<template>
  <v-container fluid>
    <section-header
      :title="$t('settingsPage.about')"
      :has-action="false"
    />

    <v-card
      class="py-6 px-6 mt-3"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
          offset-sm="2"
          md="6"
          offset-md="3"
        >
          <AboutImg />
        </v-col>

        <v-col
          cols="12"
          sm="8"
          offset-sm="2"
          md="6"
          offset-md="3"
          class="mt-5 text-left"
        >
          <p class="text-body-1">
            {{ $t('about.companyIntro') }}
          </p>

          <p class="text-body-1">
            ©2024 Testfiesta Corp.
          </p>

          <p class="text-h6 mt-10">
            {{ $t('about.contactUs') }}
          </p>

          <p class="text-body-1 mb-1">
            {{ $t('about.lookForward') }}
          </p>

          <p class="text-body-1">
            <a href="mailto:support@testfiesta.com">support@testfiesta.com</a>
          </p>

          <p class="text-h6 mt-10">
            {{ $t('about.legalDocuments') }}
          </p>

          <p class="text-body-1">
            <a
              href="https://google.com"
              target="_blank"
            >{{ $t('about.privacyPolicy') }}</a>
          </p>

          <p class="text-body-1">
            <a
              href="https://google.com"
              target="_blank"
            >{{ $t('about.termsOfUse') }}</a>
          </p>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import SectionHeader from '@/components/Form/SectionHeader.vue';
import AboutImg from '@/assets/svg/about.svg';

export default {
  name: 'About',

  components: {
    AboutImg,
    SectionHeader,
  }
}
</script>